// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MainMenu.css */
.main-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
}

/* src/components/FormScreen.css */
.form-screen {
  max-width: 500px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input, button {
  padding: 10px;
  font-size: 16px;
}

.media-preview {
  margin-top: 20px;
}

.media-preview img {
  max-width: 100%;
  max-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/MainMenu.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA,kCAAkC;AAClC;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/* src/components/MainMenu.css */\r\n.main-menu {\r\n  display: grid;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  gap: 20px;\r\n  max-width: 600px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.menu-item {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 150px;\r\n  background-color: #3498db;\r\n  color: white;\r\n  text-decoration: none;\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  border-radius: 10px;\r\n}\r\n\r\n/* src/components/FormScreen.css */\r\n.form-screen {\r\n  max-width: 500px;\r\n  margin: 0 auto;\r\n}\r\n\r\nform {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n}\r\n\r\ninput, button {\r\n  padding: 10px;\r\n  font-size: 16px;\r\n}\r\n\r\n.media-preview {\r\n  margin-top: 20px;\r\n}\r\n\r\n.media-preview img {\r\n  max-width: 100%;\r\n  max-height: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
