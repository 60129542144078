import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './FormScreen.css';

function FormScreen() {
  const { formType } = useParams();
  const [formData, setFormData] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isPicPosted, setIsPicPosted] = useState(false);


  useEffect(() => {
    //document.title = "GreenSense - Eco data sensor, powered by AI";
    fetchFormFields();
  }, []);


  const fetchFormFields = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {  
        const response = await axios.get('https://llmapi.nlightnconsulting.com/form/Electricity_Meter_Readings', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        console.log(response);
        setFormFields(response.data);
      } else {
        console.log('No access token found');
      }
    } catch (error) {
      console.error('Failed to fetch form fields:', error);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/'; // Redirect to home page
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    // Here you would typically send the file to your backend
    console.log('File uploaded:', file.name);
     // Show image preview
     const reader = new FileReader();
     reader.onloadend = () => {
       setImagePreview(reader.result);
     };
     reader.readAsDataURL(file);
  };

  /* const handleVoiceInput = () => {
    // Web Speech API
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log('Voice input:', transcript);
        // In a real app, you'd send this to your backend and update formData with the response
      };
      recognition.start();
    } else {
      alert('Speech recognition is not supported in this browser.');
    }
  }; */

  const handleVoiceInput = () => {
    // MediaRecorder API
    const mediaStreamConstraints = { audio: true };
    navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
      .then(stream => {
        const mediaRecorder = new MediaRecorder(stream);
        let audioChunks = [];
  
        mediaRecorder.start();
        setIsRecording(true);

        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        // Stop recording manually
        document.getElementById('stopButton').addEventListener('click', () => {
          mediaRecorder.stop();
          setIsRecording(false);
          setIsLoading(true);
        });        
  
        mediaRecorder.addEventListener("stop", () => {
          
          // Stop all tracks in the stream
          stream.getTracks().forEach(track => track.stop());

          const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
          const formData2 = new FormData();
          formData2.append('file', audioBlob, 'audio.webm');
  
          // Send the audio file to the backend
          axios.post('https://llmapi.nlightnconsulting.com/transcribe', formData2)
            .then(response => {
              console.log('Transcription:', response.data);
              // Update formData with the response
              let updatedFormData = { ...formData };
              for (let key in response.data) {
                if (response.data[key] !== null && response.data[key] !== '') {
                  updatedFormData[key] = response.data[key];
                }
              }
              console.log(updatedFormData);
              setFormData(updatedFormData);
              setIsLoading(false);
            })
            .catch(error => {
              console.error('Failed to transcribe audio:', error);
            });
        });
  
        /* // Stop recording after 5 seconds
        setTimeout(() => {
          mediaRecorder.stop();
        }, 5000); */
      })
      .catch(error => {
        console.error('Failed to access microphone:', error);
        alert('Failed to access microphone. Please check your browser settings.');
      });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.style.display = 'block';
        setIsCameraOn(true);
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, 640, 480);
      const imageDataUrl = canvasRef.current.toDataURL('image/jpeg');
      setImagePreview(imageDataUrl);
      console.log('Image captured:', imageDataUrl);
      
      // Convert imageDataUrl to a Blob
      fetch(imageDataUrl)
      .then(res => res.blob())
      .then(blob => {
        // Append the image Blob to formData2
        const formData2 = new FormData();
        formData2.append('file', blob, 'image.jpeg');
        formData2.append('doctype', 'electricitybill');

        // Stop all tracks of the stream
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          videoRef.current.srcObject = null;
          videoRef.current.style.display = 'none';
        }

        setIsPicPosted(true);

        // Send the picture file to the backend
        axios.post('https://llmapi.nlightnconsulting.com/picpost', formData2)
          .then(response => {
            console.log('Form data:', response.data);
            // Update formData with the response
            let updatedFormData = { ...formData };
            for (let key in response.data) {
              if (response.data[key] !== null && response.data[key] !== '') {
                updatedFormData[key] = response.data[key];
              }
            }
            setFormData(updatedFormData);
          })
          .catch(error => {
            console.error('Failed to transcribe image:', error);
          });
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send formData to backend
    // Create the data object to match the expected backend structure
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      const data = {
        formData: formData
      };
      console.log('Submitting form data:', data);
      try {
        const response = await axios.post('https://llmapi.nlightnconsulting.com/api/sheets', data, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json', // Set the content type to JSON
          },
        });
        console.log('Response:', response);
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    } else {
      console.log('No access token found.');
    }
  };

  return (
    <div className="container form-screen">
      <h3 className="text-center mt-3">{formType}</h3>
      <form onSubmit={handleSubmit} className="mt-5">
        <div className="row">
          {formFields.map((field, index) => (
            <div className="form-group col-md-6" key={index}>
              <label htmlFor={field.field_name}>{field.label}</label>
              <input
                className="form-control"
                type={field.datatype}
                name={field.field_name}
                id={field.field_name}
                placeholder={field.field_name === 'Date' ? new Date().toISOString().split('T')[0] : "Enter value..."}
                onChange={handleInputChange}
                value={formData[field.field_name] || ''}
              />
            </div>
          ))}
        </div>
        <div className="form-group">
          <input type="file" accept="image/*" onChange={handleFileUpload} className="form-control-file" />
        </div>
        <div className="form-group d-flex">
          <button type="button" onClick={handleVoiceInput} className="btn btn-primary mr-2" disabled={isRecording || isLoading}>Voice Input</button>
          <button type="button" id="stopButton" className="btn btn-secondary" disabled={!isRecording || isLoading}>Stop Recording</button>
        </div>
        <div className="media-preview mt-5">
          <video ref={videoRef} autoPlay style={{ display: 'none' }}></video>
          <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
          {imagePreview && <img src={imagePreview} alt="Preview" className="img-thumbnail mt-3" />}
        </div>
        <div className="form-group d-flex">
          <button type="button" onClick={startCamera} className="btn btn-primary mr-2">Start Camera</button>
          <button type="button" onClick={captureImage} className="btn btn-secondary">Capture Image</button>
        </div>
        <button type="submit" className="btn btn-success">Submit</button>
      </form>
    </div>
  );
}

export default FormScreen;