// LoginPage.js
import React, { useState, useEffect } from 'react';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    // Handle login logic here
    console.log(username, password);
  };

  const handleSignIn = () => {
    window.location.href = 'https://llmapi.nlightnconsulting.com/auth/google';
  };

  console.log('Rendering LoginPage');
  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        {/* <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <input type="submit" value="Login" /> */}
        <button onClick={handleSignIn}>Sign in with Google</button>
      </form>
    </div>
  );
}

export default LoginPage;