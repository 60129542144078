import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MainMenu from './components/MainMenu';
import LoginPage from './components/LoginPage';
import FormScreen from './components/FormScreen';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    document.title = "GreenSense - Eco data sensor, powered by AI";
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const refreshToken = urlParams.get('refresh_token');
    console.log(accessToken, refreshToken);
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
      setIsLoggedIn(true);
    }

    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }
  }, []);

  return (
    <Router>
      <div className="App">
      <header className="App-header">
          <h3 className="text-center">GreenSensor - Eco data sensor</h3>
        </header>
        <main>
        <Routes>
        {isLoggedIn ? (
        <Route path="/" element={<MainMenu />} />
        ) : (
          <Route path="/" element={<LoginPage />} />
        )}
          <Route path="/form/:formType" element={<FormScreen />} />
        </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;