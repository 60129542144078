import React from 'react';
import { Link } from 'react-router-dom';
import './MainMenu.css';

const menuItems = [
  { title: 'Electricity Meter', key: 'Electricity_Meter_Readings' },
  { title: 'Electricity Bill', key: 'Electricity_Bill_Details' },
  { title: 'Water Meter', key: 'Water_Meter_Readings' },
  { title: 'Water Bill', key: 'Water_Bill_Details' },
];

function MainMenu() {
  return (
    <div className="main-menu">
      {menuItems.map((item) => (
        <Link key={item.key} to={`/form/${item.key}`} className="menu-item">
          {item.title}
        </Link>
      ))}
    </div>
  );
}

export default MainMenu;